import { Button, Result } from 'antd';
import { createBrowserRouter, LoaderFunctionArgs, Outlet, RouteObject } from 'react-router-dom';
import { prebuiltRoutes } from '../features/prebuilts/prebuilts.router';
import { wizardRoutes } from '../features/wizard/wizard.router';
import { IFrameCommunicator } from '../shared/authorize-net/components/IFrameCommunicator';
import { productsTemplatesRoutes } from '../features/products-templates/products-templates.router';
import { buildingPackagesRoutes } from '../features/building-packages/building-packages.router';
import { stockRequestsRoutes } from '../features/stock-requests/stock-requests.router';
import { RouteProtected } from '../shared/models/route-protected.model';
import { ProtectedRoute } from '../shared/components/router/ProtectedRoute';
import { preownedRoutes } from '../features/preowned/preowned.router';

export type rootLoaderResult = {
    siteId: string | null;
};
const appRoute: RouteProtected = {
    path: '',
    loader: ({ params, request }: LoaderFunctionArgs): NonNullable<rootLoaderResult> => {
        return {
            siteId: params['siteId'] ?? new URL(request.url).searchParams.get('siteId'),
        };
    },
    shouldRevalidate: () => false,
    id: 'root',
    children: [
        ...wizardRoutes,
        prebuiltRoutes,
        preownedRoutes,
        productsTemplatesRoutes,
        buildingPackagesRoutes,
        stockRequestsRoutes,
        {
            path: 'iframe-communicator',
            element: <IFrameCommunicator />,
        },
        {
            path: '*',
            element: (
                <Result
                    status='404'
                    title='404'
                    subTitle='Sorry, the page you visited does not exist.'
                    extra={
                        <Button type='primary' href='/'>
                            Back Home
                        </Button>
                    }
                />
            ),
        },
    ],
};

const buildProtectedRoutes = (routes: RouteProtected[]) => {
    for (let i = 0; i < routes.length; i++) {
        const { permissions, ...route } = routes[i];
        if (route.children) route.children = buildProtectedRoutes(route.children);

        if (!!permissions && permissions.length > 0) {
            const protectedRoute: RouteObject = {
                element: (
                    <ProtectedRoute permissions={permissions} routeId={'root'}>
                        <Outlet />
                    </ProtectedRoute>
                ),
                children: [route],
            };

            routes[i] = protectedRoute;
        }
    }

    return routes;
};

export const appRoutes = buildProtectedRoutes([appRoute]);
export const AppBrowserRouter = createBrowserRouter(appRoutes);
