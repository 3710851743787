import {
    RouteObject,
    redirect,
    Navigate,
    defer,
    useAsyncValue,
    NonIndexRouteObject,
    LoaderFunctionArgs,
    json,
} from 'react-router-dom';
import { ConfigurationBaseModel } from '../../shared/models/configuration-base.model';
import { ConfiguratorAPI } from '../../shared/services/configurator.service';
import { ConfiguratorPage } from '../configurator/ConfiguratorPage';
import { CustomerPage } from '../customer/CustomerPage';
import { TrackPageView } from '../ga/TrackPageView';
import { OrderCreatedPage } from '../orders/OrderCreatedPage';
import { Prebuilt } from '../prebuilts/prebuilt.model';
import { ProductsPage } from '../products/ProductsPage';
import { ReviewAndPay } from '../reviewAndPay/ReviewAndPay';
import { SummaryPage } from '../summary/SummaryPage';
import { WidgetAction, WidgetInitState } from '../widget/Widget.slice';
import { WidgetAsyncError } from '../widget/WidgetAsyncError';
import { WidgetAsyncLoader } from '../widget/WidgetAsyncLoader';
import { Wizard } from './Wizard';
import { StoresService } from '../../shared/services/stores.service';
import { Store } from '../../shared/models/store.model';
import { Preowned } from '../preowned/preowned.model';
export type DeferredData<T> = Promise<T> & {
    _data: T;
};
export type WizardLoaderResult = {
    masterConfig: ConfigurationBaseModel | null;
    initialParams: WidgetInitState['initialConfiguration'];
    prebuilt: Prebuilt | null;
    preowned: Preowned | null;
    dealId: string | null;
    action: WidgetAction;
    siteId: string | null;
    actionType: WidgetInitState['actionType'] | null;
    sourceMasterConfigId: string | null;
    changedDescriptionId: string | null;
    allowVariants?: boolean;
    store?: Store;
};
export interface WizardLoaderDeferredResult extends Record<string, unknown> {
    init: DeferredData<WizardLoaderResult>;
}

export const ROUTE_DEFAULT = '';
export const ROUTE_CONFIGURATOR = 'configurator';
export const ROUTE_PRODUCTS = 'products';
export const ROUTE_CUSTOMER_INFO = 'customer';
export const ROUTE_SUMMARY = 'summary';
export const ROUTE_REVIEW = 'review';
export const ROUTE_CHECKOUT = 'checkout';
export const ROUTE_ORDER_CREATED = 'order-created';
export const ROUTE_PAYMENT_SUCCESS = 'payment-success';
export const ROUTE_PAYMENT_ERROR = 'payment-error';

const DeprecateRedirectToConfigurator = (): JSX.Element => {
    const productId = useAsyncValue();

    return <Navigate to={'/configurator'} replace={true} state={{ productId }} />;
};

export const RedirectToConfigurator = (): JSX.Element => {
    const { masterConfig } = useAsyncValue() as WizardLoaderResult;
    return (
        <Navigate
            to={masterConfig?.productId ? ROUTE_CONFIGURATOR : ROUTE_PRODUCTS}
            replace={true}
            state={{ productId: masterConfig?.productId }}
        />
    );
};

const WizardComponent = (
    <WidgetAsyncLoader<WizardLoaderDeferredResult> resolverSelector={({ init }) => init}>
        <Wizard />
    </WidgetAsyncLoader>
);

export const WizardChildRoutes: RouteObject[] = [
    {
        path: ROUTE_PRODUCTS,
        shouldRevalidate: () => false,
        element: (
            <TrackPageView>
                <ProductsPage />
            </TrackPageView>
        ),
    },
    {
        path: ROUTE_CONFIGURATOR,
        element: (
            <TrackPageView>
                <ConfiguratorPage />
            </TrackPageView>
        ),
    },
    {
        path: ROUTE_CUSTOMER_INFO,
        element: (
            <TrackPageView>
                <CustomerPage />
            </TrackPageView>
        ),
    },
    {
        path: ROUTE_SUMMARY,
        element: (
            <TrackPageView>
                <SummaryPage />
            </TrackPageView>
        ),
    },
    {
        path: ROUTE_REVIEW,
        element: (
            <TrackPageView>
                <ReviewAndPay />
            </TrackPageView>
        ),
    },
    {
        path: ROUTE_ORDER_CREATED,
        element: (
            <TrackPageView>
                <OrderCreatedPage />
            </TrackPageView>
        ),
    },
];

const wizardEditRoutes: NonIndexRouteObject = {
    path: '',
    children: [
        {
            path: ':masterId',
            loader: async ({ params, request }: LoaderFunctionArgs): Promise<unknown | Response> => {
                const masterId = params['masterId'];
                if (!masterId) return redirect('/');

                const qs = new URL(request.url).searchParams;
                const siteId = qs.get('siteId');
                const actionType = qs.get('actionType');
                const sourceMasterConfigId = qs.get('sourceMasterConfigurationId');

                let initialParams = {};
                if (qs.has('ic')) {
                    const ic = qs.get('ic');
                    if (ic) {
                        const query = new URLSearchParams(window.atob(ic));

                        initialParams = Object.fromEntries(query.entries());
                    }
                }

                if (qs) {
                    return defer({
                        init: ConfiguratorAPI.getConfigurationByMasterId(masterId).then((masterConfig) => ({
                            masterConfig,
                            initialParams,
                            prebuilt: null,
                            dealId: null,
                            action: 'edit',
                            siteId,
                            actionType,
                            sourceMasterConfigId,
                            changedDescriptionId: null,
                            allowVariants: undefined,
                            store: undefined,
                        })),
                    } as WizardLoaderDeferredResult);
                }
            },
            shouldRevalidate: () => false,
            children: [
                {
                    path: '',
                    element: <Navigate to={ROUTE_CONFIGURATOR} replace={true} />,
                },
                ...WizardChildRoutes,
            ],
            element: WizardComponent,
        },
    ],
};

const wizardNewRoutes: NonIndexRouteObject = {
    path: '',
    loader: async ({ request }: LoaderFunctionArgs): Promise<unknown> => {
        const url = new URL(request.url);
        const qs = url.searchParams;
        let action: WidgetAction = 'new';
        let sourceConfig: ConfigurationBaseModel | null = null;
        const productId = qs.get('productId');
        const dealId = qs.get('dealId');
        const siteId = qs.get('siteId');
        const actionType = qs.get('actionType');
        const changedDescriptionId = qs.get('changedDescriptionId');
        const allowVariantsParam = qs.get('allowVariants');
        const allowVariants = !!allowVariantsParam ? allowVariantsParam.toLowerCase() === String(true) : undefined;
        const storeId = qs.get('storeId');
        let store$: Promise<Store>;

        if (storeId) {
            store$ = StoresService.fetchStore(storeId);
        }

        const sourceMasterConfigId = qs.get('sourceMasterConfigurationId');
        if (!!sourceMasterConfigId) {
            sourceConfig = await ConfiguratorAPI.getConfigurationByMasterId(sourceMasterConfigId);
            if (!!sourceConfig) action = 'copy';
        } else {
        }

        let initialParams = {};

        if (qs.has('ic')) {
            const ic = qs.get('ic');
            if (ic) {
                const query = new URLSearchParams(window.atob(ic));

                initialParams = Object.fromEntries(query.entries());
            }
        }

        return defer({
            init: Promise.resolve(sourceConfig ?? ({ productId } as ConfigurationBaseModel)).then(
                async (masterConfig) => ({
                    masterConfig,
                    initialParams,
                    prebuilt: null,
                    preowned: null,
                    dealId,
                    action,
                    siteId,
                    actionType,
                    sourceMasterConfigId,
                    changedDescriptionId,
                    allowVariants,
                    store: await store$,
                }),
            ),
        } as WizardLoaderDeferredResult);
    },
    shouldRevalidate: () => false,
    children: [
        {
            path: '',
            element: <RedirectToConfigurator />,
        },
        ...WizardChildRoutes,
    ],
    element: WizardComponent,
};

export const deprecatedWizardRoutes: RouteObject[] = [
    {
        path: [ROUTE_CONFIGURATOR, ROUTE_PRODUCTS].join('/'),
        element: <Navigate to={'/products'} replace={true} />,
    },
    {
        path: [ROUTE_CONFIGURATOR, ROUTE_PRODUCTS, ':productId'].join('/'),
        loader: ({ params }) => defer({ productId: params['productId'] }),
        element: (
            <WidgetAsyncLoader<{ productId: string }> resolverSelector={(data) => data.productId}>
                <DeprecateRedirectToConfigurator />
            </WidgetAsyncLoader>
        ),
    },
];
export const wizardRoutes: RouteObject[] = [
    ...deprecatedWizardRoutes,
    {
        path: '',
        loader: ({ request }: LoaderFunctionArgs): Response => {
            const qs = new URLSearchParams(request.url.split('?')[1]);

            if (qs.get('uuid')) {
                return redirect(qs.get('uuid') + '/configurator');
            } else {
                return json({});
            }
        },
        children: [wizardNewRoutes, wizardEditRoutes],
        errorElement: <WidgetAsyncError />,
    },
];
