import { IInitializationProvider, ProductConfiguration } from '@ulrichlifestyle/configurator';
import React, { useCallback } from 'react';
import { Environment } from '../../app/environment';
import { App, ConfigProvider, theme } from 'antd';
import { NotificationPlacement } from 'antd/es/notification/interface';
import logger from '../../app/logger';
import styles from './ProductConfigurator.module.scss';

const notificationDuration = 5;
const notificationPlacement: NotificationPlacement = 'bottomLeft';
const errorMessage = 'An unexpected error occurred';
const notificationKey = 'prebuilt-notification';

export type ProductConfiguratorProps = Omit<
    IInitializationProvider,
    'initializationThreekit' | 'logicConfig' | 'threeKitId' | 'onError'
> & {
    containerClassName?: string;
    logicConfig: IInitializationProvider['logicConfig'];
    threeKitId?: string;
    onError?: IInitializationProvider['onError'];
};

const ProductConfiguratorImpl = ({
    containerClassName,
    logicConfig,
    threeKitId,
    onError,
    ...initConfig
}: ProductConfiguratorProps): JSX.Element => {
    const { notification } = App.useApp();

    const showError = useCallback(
        (error: Error) => {
            if (notification) {
                const message = Environment.isDevelopment ? `${error.message}.` : '';

                notification.error({
                    message: errorMessage,
                    description: `${message}Please try again later`,
                    duration: notificationDuration,
                    placement: notificationPlacement,
                    key: notificationKey,
                });
            }
            logger.error(error);
        },
        [notification],
    );

    return (
        <div className={[containerClassName ?? '', styles['product-configurator-container']].join(' ')}>
            <ConfigProvider theme={theme.defaultConfig}>
                <ProductConfiguration
                    initializationThreekit={{
                        threekitUrl: Environment.threekitUrl,
                        authToken: Environment.threekitApiToken,
                        assetId: Environment.threekitAssetId,
                        apiToken: `Bearer ${Environment.logikApiToken}`,
                        apiUrl: Environment.logikApiUrl,
                        orgId: Environment.threekitOrgId,
                        eighthWallApiKey: Environment.appEighthWallApiKey,
                    }}
                    logicConfig={logicConfig}
                    reconfigurationConfig={threeKitId ? { threeKitId } : undefined}
                    onError={onError ?? showError}
                    {...initConfig}
                />
            </ConfigProvider>
        </div>
    );
};

export const ProductConfigurator = React.memo(ProductConfiguratorImpl, (prev, next) => {
    return prev.logicConfig.uuid === next.logicConfig.uuid;
});
