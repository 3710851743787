import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { Environment } from './environment';
import authReducer from '../features/auth0/Auth0.slice';
import miniPlayerReducer from '../features/mini-player/MiniPlayer.slice';
import widgetReducer from '../features/widget/Widget.slice';
import checkoutReducer from '../features/checkout/Checkout.slice';
import prebuiltsReducer from '../features/prebuilts/prebuilts.slice';
import productsReducer from '../features/products/Products.slice';
import preownedReducer from '../features/preowned/preowned.slice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        miniPlayer: miniPlayerReducer,
        widget: widgetReducer,
        checkout: checkoutReducer,
        prebuilt: prebuiltsReducer,
        preowned: preownedReducer,
        products: productsReducer,
    },
    devTools: Environment.isDevelopment,
});
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
